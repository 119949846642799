import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-carlsbad-california.png'
import image0 from "../../images/cities/scale-model-of-the-flower-fields-in-carlsbad-california.png"
import image1 from "../../images/cities/scale-model-of-dino-island-in-carlsbad-california.png"
import image2 from "../../images/cities/scale-model-of-fun-town-in-carlsbad-california.png"
import image3 from "../../images/cities/scale-model-of-public-art-\"bird-of-paradise\"-in-carlsbad-california.png"
import image4 from "../../images/cities/scale-model-of-the-grid-in-carlsbad-california.png"
import image5 from "../../images/cities/scale-model-of-lego-factory-tour-in-carlsbad-california.png"
import image6 from "../../images/cities/scale-model-of-castle-hill-in-carlsbad-california.png"
import image7 from "../../images/cities/scale-model-of-pirate-shores-in-carlsbad-california.png"
import image8 from "../../images/cities/scale-model-of-legoland-california-in-carlsbad-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Carlsbad'
            state='California'
            image={image}
            lat='33.1580933'
            lon='-117.35059390000004'
            attractions={ [{"name": "The Flower Fields", "vicinity": "5704 Paseo Del Norte, Carlsbad", "types": ["point_of_interest", "establishment"], "lat": 33.1230447, "lng": -117.3175875}, {"name": "Dino Island", "vicinity": "1 Legoland Dr, Carlsbad", "types": ["point_of_interest", "establishment"], "lat": 33.1264312, "lng": -117.31262220000002}, {"name": "Fun Town", "vicinity": "1 Legoland Dr, Carlsbad", "types": ["point_of_interest", "establishment"], "lat": 33.1278, "lng": -117.3132}, {"name": "Public Art \"Bird of Paradise\"", "vicinity": "2031740600, Carlsbad", "types": ["point_of_interest", "establishment"], "lat": 33.1584175, "lng": -117.3509431}, {"name": "The GRID", "vicinity": "206 Artist Alley Suite A, Oceanside", "types": ["point_of_interest", "establishment"], "lat": 33.1963361, "lng": -117.37942270000002}, {"name": "Lego Factory Tour", "vicinity": "1 Legoland Dr, Carlsbad", "types": ["point_of_interest", "establishment"], "lat": 33.1284633, "lng": -117.3137079}, {"name": "Castle Hill", "vicinity": "1 Legoland Dr, Carlsbad", "types": ["point_of_interest", "establishment"], "lat": 33.1288728, "lng": -117.31050270000003}, {"name": "Pirate Shores", "vicinity": "1 Legoland Dr, Carlsbad", "types": ["point_of_interest", "establishment"], "lat": 33.1290318, "lng": -117.31198159999997}, {"name": "LEGOLAND California", "vicinity": "One Legoland Dr, Carlsbad", "types": ["point_of_interest", "establishment"], "lat": 33.1261431, "lng": -117.31154800000002}] }
            attractionImages={ {"The Flower Fields":image0,"Dino Island":image1,"Fun Town":image2,"Public Art \"Bird of Paradise\"":image3,"The GRID":image4,"Lego Factory Tour":image5,"Castle Hill":image6,"Pirate Shores":image7,"LEGOLAND California":image8,} }
       />);
  }
}